import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as RegenerateIcon } from 'assets/icons/regenerate.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { RoundButton } from 'components/ui/RoundedButton/RoundedButton';
import { useLayoutForm } from 'hooks/use-layout-form';
import { useAuth } from 'hooks/use-auth';
import { useProfile } from 'queries/useProfile';
import { usePresentation } from 'queries/useLayout';

interface AsidePresentationProps {
  isGenerating: number;
  downloadPPTX(): void;
}

export const AsidePresentation: FC<AsidePresentationProps> = ({ isGenerating, downloadPPTX }) => {
  const navigation = useNavigate();
  const params = useParams();

  const { openAuth } = useAuth();
  const { profile } = useProfile();
  const { setPresentation, isError, toggleIsError } = useLayoutForm();
  const { presentation } = usePresentation(params.id);

  const isEditable = presentation.data ? presentation.data?.presentation.user_id === profile.data?.id : false;

  const back = () => {
    setPresentation(null);
    navigation('/');
  };

  if (isEditable) {
    const submitForm = () => {
      if (isError) {
        setTimeout(() => {
          toggleIsError(false);
        }, 0);
      } else {
        document.querySelector('#layout-form')?.dispatchEvent(new Event('submit'));
      }
    };
    return (
      <Stack gridArea="buttons" alignItems="flex-end" spacing={2}>
        {profile.data ? (
          <RoundButton sx={{ overflow: 'hidden' }} onClick={() => navigation('/profile')} title="Profile">
            <img alt="user-pic" src={`${process.env.REACT_APP_BASE_URL}/${profile.data.avatar}`} />
          </RoundButton>
        ) : (
          <RoundButton onClick={openAuth} title="Sign In">
            <UserIcon />
          </RoundButton>
        )}
        <RoundButton onClick={back} title="Close">
          <CloseIcon />
        </RoundButton>
        <RoundButton
          loading={isGenerating > 0}
          type="submit"
          onClick={submitForm}
          form="layout-form"
          title="Regenerate"
        >
          <RegenerateIcon />
        </RoundButton>
        <RoundButton onClick={downloadPPTX} sx={{ backgroundColor: '#FC9F72' }} title="Download">
          <DownloadIcon />
          <Typography color="primary.main" fontSize="16px">
            .pptx
          </Typography>
        </RoundButton>
      </Stack>
    );
  }

  return (
    <Stack gridArea="buttons" alignItems="flex-end" spacing={2}>
      <RoundButton onClick={back} title="Close">
        <CloseIcon />
      </RoundButton>
    </Stack>
  );
};
