import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as CloseIcon } from 'assets/icons/closeDark.svg';
import { DialogPaper } from 'components/ui/DialogPaper';
import { ButtonShade } from 'components/ui/ButtonShade/ButtonShade';
import { useAuthQuery } from 'queries/useAuth';

type AuthorizationProps = DialogProps;

export const Authorization: FC<AuthorizationProps> = ({ open = false, onClose }) => {
  const [step, setStep] = useState(0);
  const { control, handleSubmit, reset, setError, getValues } = useForm({
    defaultValues: {
      email: '',
      otp: '',
    },
  });
  const { auth, confirm } = useAuthQuery();

  // useLayoutEffect(() => {
  //   if (hasEmail) {
  //     setValue('email', hasEmail);
  //     setStep(1);
  //   }
  // }, []);

  const handleClose = () => {
    onClose?.({}, 'backdropClick');
    if (getValues().otp !== '') {
      setStep(0);
    }
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (step === 0) {
      auth.mutate(data, {
        onError(error: any) {
          if (error && error.error && typeof error.error === 'string') {
            setError('email', { message: error.error }, { shouldFocus: true });
          }
        },
        onSuccess() {
          setStep(1);
        },
      });
    } else if (step === 1) {
      confirm.mutate(data, {
        onError(error: any) {
          if (error && error.error && typeof error.error === 'string') {
            setError('otp', { message: error.error }, { shouldFocus: true });
          }
        },
        onSuccess() {
          handleClose();
        },
      });
    }
  });

  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={DialogPaper}>
      <DialogTitle>
        <Box display="flex">
          <Typography variant="h4" sx={{ flexGrow: 1, mt: '20px' }} color="white">
            {step === 0 && 'Authorization'}
            {step === 1 && 'Sign in'}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {step === 1 && (
          <Typography color="white" mb={2}>
            Type in a 4-digits code, we’ve sent to your {getValues().email} email
          </Typography>
        )}
        <form id="auth-form" onSubmit={onSubmit}>
          {step === 0 && (
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  message: 'Incorrect email',
                  value: /^\S+@\S+\.\S+$/,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  label="Your Email:"
                  variant="standard"
                  placeholder="experiment@experiment.com"
                  fullWidth
                  InputLabelProps={{
                    sx: {
                      color: '#646464 !important',
                    },
                  }}
                  InputProps={{
                    sx: {
                      '& input': {
                        paddingBottom: '20px',
                      },
                      '&:before, &:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderColor: '#525252',
                      },
                      '&:after': {
                        borderColor: '#ffffff',
                      },
                      '&:not(.Mui-disabled)': {
                        color: '#ffffff',
                      },
                      fontSize: '21px',
                    },
                  }}
                />
              )}
            />
          )}
          {step === 1 && (
            <Controller
              name="otp"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'OTP code should be 4 digits long',
                },
                pattern: {
                  message: 'OTP code should be 4 digits long',
                  value: /^[0-9]{4}$/, // enforce 4-digit limit and digits only
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  onKeyDown={(event) => {
                    // prevent typing any non-numeric characters except backspace and delete
                    if (
                      event.key !== 'Backspace' &&
                      event.key !== 'Delete' &&
                      !/\d/.test(event.key) &&
                      event.key !== 'Enter'
                    ) {
                      event.preventDefault();
                    }
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  label="Enter OTP:"
                  variant="standard"
                  placeholder="0000"
                  fullWidth
                  InputLabelProps={{
                    sx: {
                      color: '#646464 !important',
                    },
                  }}
                  InputProps={{
                    sx: {
                      '& input': {
                        paddingBottom: '20px',
                      },
                      '&:before, &:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderColor: '#525252',
                      },
                      '&:after': {
                        borderColor: '#ffffff',
                      },
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '&:not(.Mui-disabled)': {
                        color: '#ffffff',
                      },
                      fontSize: '21px',
                    },
                  }}
                />
              )}
            />
          )}
          <Box sx={{ display: 'none' }}>
            <input type="submit" />
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {step === 0 ? (
          <ButtonShade loadingPosition="start" onClick={onSubmit}>
            Send OTP
          </ButtonShade>
        ) : (
          <ButtonShade loadingPosition="start" onClick={onSubmit}>
            Authorize
          </ButtonShade>
        )}
      </DialogActions>
    </Dialog>
  );
};
